import { images } from "../images";

export const singamsTvMain = [
  // {
  //   id: 1,
  //   img: images.singamstv24.image,
  //   mbImg: images.singamstv24Mob.image,
  //   title: "Chennai Singams secure 59-run win over Bangalore Strikers",
  //   link: "https://www.youtube.com/watch?v=Lc-kI73AAk0",
  //   time: "11:57",
  // },
  {
      id: 1,
      img: images.singamstv32.image,
      mbImg: images.singamstv32Mob.image,
      title: "ISPL: Jagannath Sarkar on Chennai’s loss: 'Small moments made the difference'",
      link: "https://www.youtube.com/embed/y3POTScEaJ0?si=WTqyYt-oR2Qi7yyC",
      time: "2:06",
    },
];

export const singamsTvData = [
    // 4-2-25 update
  // {
  //   id: 1,
  //   img: images.singamstv25.image,
  //   title: "Chennai Singams defeat Srinagar Ke Veer | Highlights",
  //   link: "https://www.youtube.com/watch?v=_5sz5M0vX1A",
  //   time: "12:07",
  // },
  // {
  //   id: 2,
  //   img: images.singamstv26.image,
  //   title: "ISPL: Jagannath Sarkar on Chennai’s loss: 'Small moments made the difference'",
  //   link: "https://www.youtube.com/embed/y3POTScEaJ0?si=WTqyYt-oR2Qi7yyC",
  //   time: "2:06",
  // },
  {
    id: 1,
    img: images.singamstv27.image,
    title: "'Want to dedicate half century to mom and dad': Chennai Singams’ Ketan Mhatre",
    link: "https://www.youtube.com/embed/X2e5OnTIUgQ?si=ulWHyGT7Z52fxCI6",
    time: "1:12",
  },
  {
    id: 2,
    img: images.singamstv28.image,
    title: "Advising team to take it easy helped to come back after opening defeat: Sumeet Dhekale",
    link: "https://www.youtube.com/embed/CZcXXHCbaXE?si=Qa8PYQJ5xffjbFp5",
    time: "1:42",
  },
  {
    id: 3,
    img: images.singamstv29.image,
    title: "At Chennai Singams, we create heroes both on and off the field",
    link: "https://www.youtube.com/embed/D7dQgUjcwEk",
    time: "0:18",
  },
  {
    id: 4,
    img: images.singamstv30.image,
    title: "When the non-dominant hand takes charge! Watch the Singams try their luck at hitting the stumps!",
    link: "https://www.youtube.com/embed/2CnDyn0q6RM",
    time: "1:29",
  },
  // {
  //   id: 5,
  //   img: images.singamstv31.image,
  //   title: "Chennai Singams vs Tiigers of Kolkata - Match 1",
  //   link: "https://www.youtube.com/embed/YF93-YN2vWI?si=BEDzfiJYaJcY1zcO",
  //   time: "10:37",
  // },
  // previous
  // {
  //   id: 6,
  //   img: images.singamstv01.image,
  //   title: "Chennai Singams vs KVN Bangalore Strikers - Match 2",
  //   link: "https://www.youtube.com/embed/--qDkb7pThY?si=-jQgpImobgxW9yxf",
  //   time: "11:06",
  // },
  // {
  //   id: 7,
  //   img: images.singamstv02.image,
  //   title: "Majhi Mumbai vs Chennai Singams - Match 3",
  //   link: "https://www.youtube.com/embed/h9UcMM0FuTA?si=ec96FPSJcsPiKXIS",
  //   time: "12:46",
  // },
  // {
  //   id: 8,
  //   img: images.singamstv03.image,
  //   title: "Srinagar ke Veer vs Chennai Singams - Match 4",
  //   link: "https://www.youtube.com/embed/BNafTKdVNaU?si=SI1K_hygg8BALW7k",
  //   time: "14:11",
  // },
  // {
  //   id: 9,
  //   img: images.singamstv04.image,
  //   title: "Falcon Risers Hyderabad vs Chennai Singams - Match 5",
  //   link: "https://www.youtube.com/embed/igpw_HiepdI",
  //   time: "13:43",
  // },
  // {
  //   id: 10,
  //   img: images.singamstv05.image,
  //   title: "Majhi Mumbai vs Chennai Singams - Semi final 1",
  //   link: "https://www.youtube.com/embed/xQdgdmxdPVs?si=MXji6mCzYvyxQvk4",
  //   time: "17:31",
  // },
  // {
  //   id: 11,
  //   img: images.singamstv06.image,
  //   title: "Suriya at ISPL opening ceremony",
  //   link: "https://www.youtube.com/embed/lPAnk9sj1h0?si=AFKtUl62gj-onUqE",
  //   time: "2:34",
  // },
  // {
  //   id: 12,
  //   img: images.singamstv07.image,
  //   title: "Suriya at Chennai Singams vs Tiigers of Kolkata  - Match 1",
  //   link: "https://www.youtube.com/embed/BwBOYdkNE9o?si=4FjIBvByg5uO73Oi",
  //   time: "2:16",
  // },
  // {
  //   id: 13,
  //   img: images.singamstv08.image,
  //   title: "V Vignesh Tape Ball over vs Majhi Mumbai - Semi Final 1",
  //   link: "https://www.youtube.com/embed/5RXQpN6n3MM?si=SZ_QPphzqKVFU7nQ",
  //   time: "4:42",
  // },
  // {
  //   id: 14,
  //   img: images.singamstv09.image,
  //   title:
  //     "Vishwanath Jadhav 50-50 Challenge Over vs Srinagar ke Veer - Match 4",
  //   link: "https://www.youtube.com/embed/So6low7LvZI?si=AFSNGLibN6uVxedz",
  //   time: "2:59",
  // },
  // {
  //   id: 15,
  //   img: images.singamstv10.image,
  //   title: "R Thavith Kumar 4 wicket haul vs Tiigers of Kolkata - Match 1",
  //   link: "https://www.youtube.com/embed/W9JrRhN8W5E?si=a2GoMexeQusbxznq",
  //   time: "1:25",
  // },

  {
    id: 5,
    img: images.singamstv11.image,
    title: "Happy Holi from the Singams",
    link: "https://www.youtube.com/embed/WyEes61EbMI",
    time: "0:24",
  },
  {
    id: 6,
    img: images.singamstv12.image,
    title: "Our Singams share their experience of being part of ISPL season 1",
    link: "https://www.youtube.com/embed/PHRY4M6NZN0",
    time: "0:40",
  },
  {
    id: 7,
    img: images.singamstv13.image,
    title: "Watch out for the Singams",
    link: "https://www.youtube.com/embed/nswSRCrRwO8?si=Fe6ngNneqauMAXmS",
    time: "0:30",
  },
  {
    id: 8,
    img: images.singamstv14.image,
    title: "Beware The Singams are here to",
    link: "https://www.youtube.com/embed/wBSIU06ILro?si=ibkQQnxUy_WB0cdZ",
    time: "0:34",
  },
  {
    id: 9,
    img: images.singamstv15.image,
    title: "SuriyaSivakumar adorning the Singam Squad with his presence",
    link: "https://www.youtube.com/embed/zdODysSOFlY",
    time: "0:41",
  },
  {
    id: 10,
    img: images.singamstv16.image,
    title: "A journey with sheer hardwork, determination and lots of fun",
    link: "https://www.youtube.com/embed/M-ttrLSU5t0",
    time: "0:37",
  },
  {
    id: 11,
    img: images.singamstv17.image,
    title: "Bablu Patil: Unfiltered Hear straight from our sixes machine",
    link: "https://www.youtube.com/embed/eEVfWaNZ4d4",
    time: "0:58",
  },
  {
    id: 12,
    img: images.singamstv18.image,
    title:
      "Passion goes a long way. Hear from our very own tennis cricket Baadshah - Ketan Mhatre",
    link: "https://www.youtube.com/embed/jTeaqqd10G8?si=VsU2Xqu-JzfiEHGs",
    time: "1:28",
  },
  {
    id: 13,
    img: images.singamstv19.image,
    title: "An incredible journey Our Bahubali has come a long way",
    link: "https://www.youtube.com/embed/00ChCTx4Ptw?si=lSWf8sEdMfUACbNL",
    time: "1:58",
  },
  {
    id: 14,
    img: images.singamstv20.image,
    title: "On the pitch and off it, Cricket is a vibe and vibe is at ibis",
    link: "https://www.youtube.com/embed/V4jfDwndATU",
    time: "0:15",
  },
  {
    id: 15,
    img: images.singamstv21.image,
    title:
      "After an emotionally charged cricket match, all we want is to jump into ibis comfort!",
    link: "https://www.youtube.com/embed/MGegAgmcePA",
    time: "0:42",
  },
  {
    id: 16,
    img: images.singamstv22.image,
    title: "Consistency, Discipline and Unwavering Commitment!",
    link: "https://www.youtube.com/embed/2OlyKj8eb78",
    time: "0:35",
  },
  {
    id: 17,
    img: images.singamstv23.image,
    title: "Sweating it out!",
    link: "https://www.youtube.com/embed/7pukqs7GT0I?si=ijKmf7DzynNRp_-S",
    time: "1:01",
  },
];
