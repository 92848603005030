import { images } from "../images";

export const mediaData = [
  {
    id: 1,
    img: images.media00.image,
    mbImg: images.mbMedia00.image,
    title:
      "Creating Opportunities For Tennis-Ball Players: How Chennai Singams' Rajdip Gupta Looks To Build ISPL T10",
    link: "https://www.timesnownews.com/sports/cricket/creating-opportunities-for-tennis-ball-cricketers-how-chennai-singams-rajdip-gupta-looks-to-build-ispl-t10-article-108298509",
    date: "7 Mar, 2024",
  },
  {
    id: 2,
    img: images.media21.image,
    title:
      "ibis India renews sponsorship of Chennai Singams for ISPL 2025",
    link: "https://mediabrief.com/ibis-india-renews-sponsorship-of-chennai-singams-for-ispl-2025/",
    date: "3 Feb, 2025",
  },
  {
    id: 3,
    img: images.media22.image,
    title:
      "ISPL 2: Chennai Singams end Srinagar Ke Veer's unbeaten run in high-scoring clash",
    link: "https://timesofindia.indiatimes.com/sports/cricket/news/ispl-2-chennai-singams-end-srinagar-ke-veers-unbeaten-run-in-high-scoring-clash/articleshow/117746762.cms",
    date: "30 Jan, 2025",
  },
  {
    id: 4,
    img: images.media23.image,
    title:
      "ISPL 2: Majhi Mumbai, Chennai Singams Record Clinical Victories",
    link: "https://sports.ndtv.com/cricket/ispl-2-majhi-mumbai-chennai-singams-record-clinical-victories-7583656",
    date: "29 Jan, 2025",
  },
  
  {
    id: 5,
    img: images.media24.image,
    title:
      '“ISPL 2: Grateful to be picked by Chennai Singams again,” says Farhat Ahmad',
    link: "https://www.morningkashmir.com/ispl-2-grateful-to-be-picked-by-chennai-singams-again-says-farhat-ahmad/",
    date: "20 Jan, 2025",
  },
  {
    id: 6,
    img: images.media25.image,
    title:
      "Jammu’s Deepak set to play for Chennai Singams in ISPL Season 2",
    link: "https://www.dailyexcelsior.com/jammus-deepak-set-to-play-for-chennai-singams-in-ispl-season-2/",
    date: "16 Jan, 2025",
  },
  {
    id: 7,
    img: images.media20.image,
    title:
      "Chennai Singams ISPL 2025 Squad: Full List of Players, Most Expensive Buys in Season 2 Auction",
    link: "https://www.mykhel.com/cricket/chennai-singams-ispl-2025-squad-full-list-of-players-most-expensive-buys-in-season-2-auction-326215.html",
    date: "11 Dec, 2024",
  },
  {
    id: 8,
    img: images.media01.image,
    title: "Singams all set to roar at ISPL",
    link: "https://www.mid-day.com/sports/cricket/article/singams-all-seat-to-roar-at-ispl-23338431",
    date: "5 Mar, 2024",
  },
  {
    id: 9,
    img: images.media02.image,
    title:
      "ISPL side Chennai Singams reveal team logo ahead of inaugural season",
    link: "https://sportsmintmedia.com/ispl-side-chennai-singams-reveal-team-logo-ahead-of-inaugural-season/",
    date: "14 Feb, 2024",
  },
  {
    id: 10,
    img: images.media03.image,
    title:
      "Indian Street Premier League’s Chennai Singams Unveils its Brand Identity",
    link: "https://onlinenews9.in/business/indian-street-premier-leagues-chennai-singams-unveils-its-brand-identity/",
    date: "15 Feb, 2024",
  },
  {
    id: 11,
    img: images.media04.image,
    title:
      "Indian Street Premier League’s Chennai Singams Unveils its Brand Identity",
    link: "https://businessmicro.in/business/indian-street-premier-leagues-chennai-singams-unveils-its-brand-identity/",
    date: "15 Feb, 2024",
  },
  {
    id: 12,
    img: images.media05.image,
    title:
      "Chennai Singams secures major jersey sponsorships ahead of ISPL debut",
    link: "https://indiantelevision.com/mam/media-and-advertising/sponsorship/chennai-singams-secures-major-jersey-sponsorships-ahead-of-ispl-debut-240304",
    date: "4 Mar, 2024",
  },
  {
    id: 13,
    img: images.media06.image,
    title:
      "Chennai Singam’s Lion on the Prowl for Team Players, 125 Players participated in Scouting Camp",
    link: "https://www.cnbctv18.com/sports/chennai-singams-lion-on-the-prowl-for-team-players-125-players-participated-in-scouting-camp-19099811.htm",
    date: "21 Feb, 2024",
  },
  {
    id: 14,
    img: images.media07.image,
    title:
      "Chennai Singam’s Lion on the Prowl for Team Players, 125 Players participated in Scouting Camp",
    link: "https://mediaexpress24.com/news/125-players-participated-in-scouting-camp/",
    date: "17 Feb, 2024",
  },
  {
    id: 15,
    img: images.media08.image,
    title:
      "Chennai Singam’s Lion on the Prowl for Team Players, 125 Players participated in Scouting Camp",
    link: "https://www.cityairnews.com/content/chennai-singams-lion-on-the-prowl-for-team-players-125-players-participated-in-scouting-camp",
    date: "19 Feb, 2024",
  },
  // {
  //   id: 10,
  //   img: images.media09.image,
  //   title: "Chennai Singam’s Lion on the Prowl for Team Players, 125 Players participated in Scouting Camp",
  //   link: "https://businessnewsthisweek.com/sports/125-players-participated-in-scouting-camp/",
  //   date: "17 Feb, 2024",
  // },
  {
    id: 16,
    img: images.media10.image,
    title:
      "Sumeet Dhekale to lead Chennai Singams Pride in the inaugural season of Indian Street Premier League",
    link: "https://www.cnbctv18.com/sports/sumeet-dhekale-to-lead-chennai-singams-pride-in-the-inaugural-season-of-indian-street-premier-league-19202631.htm",
    date: "5 Mar, 2024",
  },
  {
    id: 17,
    img: images.media11.image,
    title: "Chennai Singams Soar Above The Tiigers Of Kolkata",
    link: "https://gutshotmagazine.com/fantasy-sports/chennai-singams-beat-the-tiigers-of-kolkata/#:~:text=Brief%20scores%3A%20Chennai%20Singams%3A%20121,%2D13",
    date: "8 Mar, 2024",
  },
  {
    id: 18,
    img: images.media12.image,
    title:
      "Second consecutive win for Chennai Singams Dilip Binjwa’s last-over heroics take Chennai top of the table",
    link: "https://hellokotpad.com/business/second-consecutive-win-for-chennai-singams-dilip-binjwas-last-over-heroics-take-chennai-top-of-the-table/",
    date: "12 Mar, 2024",
  },
  {
    id: 19,
    img: images.media13.image,
    title: "Chennai Singams roar into ISPL semi-finals",
    link: "https://www.uniindia.com/news/sports/chennai-singams-roar-into-ispl-semi-finals/3161147.html",
    date: "14 Mar, 2024",
  },
  {
    id: 20,
    img: images.media14.image,
    title: "Chennai Singams set to play their first match today",
    link: "https://www.passionateinmarketing.com/chennai-singams-set-to-play-their-first-match-today/",
    date: "7 Mar, 2024",
  },
  {
    id: 21,
    img: images.media15.image,
    title:
      "Chennai Singhams second win in a row; Dilip Binjwa's last over was decisive",
    link: "https://www.saamana.com/chennai-singhams-win-second-match-in-tennis-ball-cricket/",
    date: "10 Mar, 2024",
  },
  {
    id: 22,
    img: images.media16.image,
    title:
      "Chennai Singams roar into ISPL semis with spirited defence against Hyderabad",
    link: "https://www.apnnews.com/chennai-singams-roar-into-ispl-semis-with-spirited-defence-against-hyderabad/",
    date: "14 Mar, 2024",
  },
  {
    id: 23,
    img: images.media17.image,
    title: "Tiigers of Kolkata, Chennai Singams seal semi-final spots",
    link: "https://kheltoday.com/ispl-t10-cricket/tiigers-of-kolkata-chennai-singams-seal-semi-final-spots/",
    date: "14 Mar, 2024",
  },
  {
    id: 24,
    img: images.media18.image,
    title:
      "ISPL T10: Bangalore Strikers fall short by 3 runs against Chennai Singhams",
    link: "https://www.republicworld.com/sports/cricket/ispl-t10-bangalore-strikers-fall-short-by-3-runs-against-chennai-singhams/?amp=1",
    date: "8 Mar, 2024",
  },
  {
    id: 25,
    img: images.media19.image,
    title:
      "ISPL T10 Day 3 Review: Chennai Singams register their second win, Tigers of Kolkata win low scoring clash against Majhi Mumbai",
    link: "https://www.bjsports.live/latest-news-en/ispl-t10-day-3-review-chennai-singams-register-their-second-win-tigers-of-kolkata-win-low-scoring-clash-against-majhi-mumbai/",
    date: "9 Mar, 2024",
  },
];
